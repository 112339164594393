<template>
    <div>
        <!-- 结算页面 -->
        <div class="box" style=""></div>
        结算
    </div>
</template>

<script>
export default {
    name: '',
    created() { },
    mounted() { },
    data() {
        return {}
    },
    methods: {},
}
</script>

<style lang=scss scoped>
@import url('../scss/Close.scss');
</style>